import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import type { UserViewTableRowProps } from '../types';

const defaultViewProps: Partial<UserViewTableRowProps> = {
  avatarSize: IconSize.PX_50,
  showOwnerOnly: true,
  useHoverCard: true
};

export default defaultViewProps;
