import type { UserViewCardProps } from '../types';

const defaultViewProps: UserViewCardProps = {
  useAvatar: true,
  useLogin: true,
  useRank: true,
  useJoinDate: true,
  useBiography: false,
  useRole: false,
  useSolutionCount: false,
  useKudosGivenCount: false,
  useKudosReceivedCount: false,
  useTopicsCount: false,
  useMessagesCount: false,
  useKudosCount: false,
  showOwnerOnly: false,
  useClickableCard: true,
  useFooterDivider: true,
  useHoverCard: false
};

export default defaultViewProps;
