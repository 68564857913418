import type { DocumentNode } from '@apollo/client';
import { parser } from '@apollo/client';
import type { ValueNode } from 'graphql';

/**
 * Helper to handle stored query variables.
 */
// eslint-disable-next-line unicorn/no-static-only-class
export default class VariableDiffer {
  /**
   * Filters out variables that are not in the query.
   *
   * @param query The GraphQL query document
   * @param variables the query variables
   */
  static scopeToQuery<T>(query: DocumentNode, variables: Record<string, unknown> | T): T {
    if (variables) {
      const queryVariables = parser(query).variables;
      const filteredVariables = {};
      const varKeys = Object.keys(variables);
      queryVariables.forEach(variableDef => {
        const variableName = variableDef.variable.name.value;
        if (varKeys.includes(variableName)) {
          Object.assign(filteredVariables, { [variableName]: variables[variableName] });
        }
      });
      return filteredVariables as T;
    } else {
      return null;
    }
  }

  /**
   * Filters out variables that have no default values in query.
   *
   * @param query The GraphQL query document
   * @param variables the query variables
   */
  static filterDefaults<T>(query: DocumentNode, variables: Record<string, unknown> | T): T {
    if (variables) {
      const vars = parser(query).variables;
      const filteredVars = {};
      vars.forEach(variableDef => {
        if (variableDef) {
          const { defaultValue } = variableDef;
          if (!defaultValue) {
            const variableName = variableDef.variable.name.value;
            Object.assign(filteredVars, { [variableName]: variables[variableName] });
          }
        }
      });
      return filteredVars as T;
    } else {
      return null;
    }
  }

  static getValue(valueNode: ValueNode): unknown {
    switch (valueNode?.kind) {
      case 'StringValue':
      case 'EnumValue': {
        return valueNode?.value ?? null;
      }
      case 'BooleanValue': {
        return valueNode?.value ?? false;
      }
      case 'IntValue': {
        return valueNode.value ? Number.parseInt(valueNode.value, 10) : 0;
      }
      case 'FloatValue': {
        return valueNode.value ? Number.parseFloat(valueNode.value) : 0;
      }
      case 'NullValue': {
        return null;
      }
      case 'ObjectValue': {
        const object = {};
        valueNode.fields.forEach(field => {
          Object.assign(object, field.name, VariableDiffer.getValue(field.value));
        });
        return object;
      }
      case 'ListValue': {
        const object = [];
        valueNode.values.forEach(field => {
          object.push(VariableDiffer.getValue(field));
        });
        return object;
      }
    }
    return null;
  }

  /**
   * Fills in default values from query.
   *
   * @param query The GraphQL query document
   * @param variables the query variables
   */
  static fillDefaults<T>(query: DocumentNode, variables: Record<string, unknown> | T): T {
    if (variables) {
      const vars = parser(query).variables;
      const filledVars = {};
      const varKeys = Object.keys(variables);
      vars.forEach(variableDef => {
        const variableName = variableDef.variable.name.value;
        // if we can't find variable among query variables, attempt to assign a default value from the query
        if (!varKeys.includes(variableName)) {
          const { defaultValue } = variableDef;
          if (defaultValue) {
            Object.assign(filledVars, {
              [variableName]: VariableDiffer.getValue(defaultValue)
            });
          }
        } else {
          Object.assign(filledVars, { [variableName]: variables[variableName] });
        }
      });
      return filledVars as T;
    } else {
      return null;
    }
  }
}
