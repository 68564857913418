import type { ItemType } from '../../../types/enums';
import { MessageViewVariant } from '../../../types/enums';
import inlineDefaultProps from '../MessageView/MessageViewInline/MessageViewInlineDefaultProps';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<
    ItemType.MESSAGE_SEARCH,
    ItemViewVariant<ItemType.MESSAGE_SEARCH>
  > = {
    type: MessageViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  return { ...inlineDefaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
