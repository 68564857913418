import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { UserViewSize } from '../../../../types/enums';
import type { UserViewInlineProps } from '../types';

const defaultViewProps: UserViewInlineProps = {
  useAvatar: true,
  avatarSize: IconSize.PX_40,
  useJoinDate: true,
  useLogin: true,
  useRank: false,
  useRole: false,
  useTitle: false,
  useMessagesCount: false,
  useSolutionCount: false,
  useKudosCount: false,
  useTopicsCount: false,
  showOwnerOnly: false,
  viewSize: UserViewSize.MD,
  useLink: true,
  useHoverCard: true,
  useEntireItemAsLink: false,
  useTopicAndSolutionCountInline: false,
  useClamp: false,
  useBio: false
};

export default defaultViewProps;
