import type { ItemType } from '../../../types/enums';
import { IdeaStatusViewVariant } from '../../../types/enums';
import inlineDefaultProps from './IdeaStatusViewInline/IdeaStatusViewInlineDefaultProps';
import cardDefaultProps from './IdeaStatusViewCard/IdeaStatusViewCardDefaultProps';
import type { IdeaStatusViewCommonProps } from './types';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';

const variantToComponentAndDefaultPropsMap: Record<
  IdeaStatusViewVariant,
  IdeaStatusViewCommonProps
> = {
  inline: inlineDefaultProps,
  card: cardDefaultProps
};

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.IDEA_STATUS, ItemViewVariant<ItemType.IDEA_STATUS>> = {
    type: IdeaStatusViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  const defaultProps = variantToComponentAndDefaultPropsMap[variant?.type];
  return { ...defaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
