import type { ItemType } from '../../../types/enums';
import { NodeViewVariant } from '../../../types/enums';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';
import inlineDefaultProps from '../NodeView/NodeViewInline/NodeViewInlineDefaultProps';

/**
 * default props for Node Search View
 *
 * @author Abhijith.Be
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.NODE_SEARCH, ItemViewVariant<ItemType.NODE_SEARCH>> = {
    type: NodeViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  return { ...inlineDefaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
