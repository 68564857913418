import type { ItemType } from '../../../types/enums';
import { UserViewVariant } from '../../../types/enums';
import inlineDefaultProps from '../../messages/MessageView/MessageViewInline/MessageViewInlineDefaultProps';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.USER_SEARCH, ItemViewVariant<ItemType.USER_SEARCH>> = {
    type: UserViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  return { ...inlineDefaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
