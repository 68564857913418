import type { ItemType } from '../../../types/enums';
import { UserViewVariant } from '../../../types/enums';
import tableRowProps from './UserViewTableRow/UserViewTableRowDefaultProps';
import inlineDefaultProps from './UserViewInline/UserViewInlineDefaultProps';
import cardDefaultProps from './UserViewCard/UserViewCardDefaultProps';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';
import type { UserViewCommonProps } from './types';

const variantToComponentAndDefaultPropsMap: Record<UserViewVariant, UserViewCommonProps> = {
  inline: inlineDefaultProps,
  card: cardDefaultProps,
  tableRow: tableRowProps
};

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.USER, ItemViewVariant<ItemType.USER>> = {
    type: UserViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  const defaultProps = variantToComponentAndDefaultPropsMap[variant?.type];
  return { ...defaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
